import React from 'react';
import { Fab } from '@material-ui/core';
import { useAktivJarat  } from '../data';
import { PlayArrow, LinkedCamera } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useDrawer } from '../hooks';

export function FuvarAction({f,style}){
  const history = useHistory();
  const {select} = useAktivJarat();
  const {openDrawer} = useDrawer();


  if(!f || f.lemondva || !f.sajat) return null;
  let lezarva = f.tetel.filter(t => t.sajat && (!t.eteny || !t.iteny)).length === 0;
  console.log("fuvar",f);

  if(lezarva){  
    return <Fab color="secondary" onClick={openDrawer('send',{id:'feltoltes',jarat:f.pos})} style={style}><LinkedCamera /> </Fab>
  }
  const start = async () =>{
    await select(f.pos);
    history.push('/');
  }

  return <Fab color="secondary" onClick={start} style={style}><PlayArrow /> </Fab>
}