import React, { useCallback, useEffect, useState, useContext, createContext } from 'react';
import { get, set } from 'idb-keyval';
import { fetchJSON, now, buildNr, version, fetchJarat, uploadFile, setEletjel, checkEletjel } from './swutils';
import moment from 'moment';
import { demo } from './hooks';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import { mindig } from './log';
import { checkEurowag, eurowagReducer } from './app/Eurowag';
import { dateFromMoment } from '../utils.mjs';

//
//config - ha ez változik, akkor refresh
//pozíció
//status


const DataCtx = createContext();
const DATA = {}; //ezt lehet használni, mert biztosan pont egy DataCtx.Provider elemünk lesz.
const channel = new BroadcastChannel('data');
channel.onmessage = event => {
  console.log('remote data change', event.data);
  if (event.data && event.data.key && DATA[event.data.key]) {
    DATA[event.data.key].setVal(event.data.v);
    console.log("setting val", { st: DATA[event.data.key], v: event.data.v })
  }
}

export function getData(key) {
  return DATA[key];
}

const send = uzenet => DATA.msg.dispatch('send', uzenet);

const useVal = ({ key, defval, onChange, reducer }) => {
  const [val, setValue] = useState(defval);
  const setVal = useCallback(v => {
    console.log("setVal", { key, v });
    DATA[key].val = v;
    setValue(v);
  }, [key, setValue]);

  const refresh = useCallback(() => get(key).then((v) => {
    if (typeof v === 'undefined') {
      setVal(defval);
    } else {
      setVal(v)
    }
  }), [key, setVal, defval]);

  useEffect(() => {
    console.log("refresh", key);
    refresh();
    // eslint-disable-next-line
  }, []);

  const save = useCallback((v) => {
    const prev = DATA[key].val;
    console.log("save", { key, v, prev });
    setVal(v);
    if (channel) {
      // console.log("Post value change", { key, v, channel });
      channel.postMessage({ key, v });
    }
    if (onChange) onChange(prev, v);
    return set(key, v);
  }, [key, onChange, setVal]);

  const dispatch = useCallback(async (type, payload) => {
    const uj = await reducer(DATA[key].val, { type, payload });
    console.log("uj " + key, uj);
    save(uj);
  }, [save, reducer, key]);

  DATA[key] = { val, save, setVal, refresh, dispatch };
  return DATA[key];
}

function gpstxt(pos) {
  if (pos && pos.coords) {
    const { latitude, longitude, speed, heading, accuracy } = pos.coords;
    return `${latitude},${longitude},${speed},${heading},${accuracy},${pos.timestamp}`;
  }
}

function msgReducer(warn) {
  const reducer = async function (msg, { type, payload }) {
    const sofor = DATA.sofor.val && DATA.sofor.val.rnev;
    switch (type) {
      case 'send': {
        console.log("Send:", payload);
        if (!msg || !msg.length) {
          msg = [];
        }
        let { km, pos, kmpos, legvonal, virtualkm } = DATA.pos.val || {};
        let uj = {
          uuid: uuidv4(),
          irany: 1,
          sofor,
          nyelv: DATA.sofor.val.nyelv,
          nysofor: sofor,
          kocsi: DATA.config.val && DATA.config.val.kocsi,
          potkocsi: DATA.potkocsi.val.frsz,
          jarat: DATA.aktivjarat.val,
          forrasid: DATA.config.val && String(DATA.config.val.id),
          stamp: now(),
          lat: pos && pos.coords && pos.coords.latitude,
          lon: pos && pos.coords && pos.coords.longitude,
          utolsokm: km,
          km: payload.km,
          legvonal,
          virtualkm: virtualkm,
          td_statusz: `version:${version}\nbuild:${buildNr}\npos:${gpstxt(pos)}\nkmpos:${gpstxt(kmpos)}`,
          pending: true,
          ...payload,
          file: false,
        };
        msg = [...msg, uj];
        DATA.msg.save(msg); //addig is kirakjuk.        
        let ret = await fetchJSON('POST', '/message', uj);
        console.log("uj üzenet:", ret);
        if (ret && ret.sorszam) uj.sorszam = ret.sorszam;
        let f = payload.file;
        if (f) {
          if (!uj.sorszam) uj.file = f; //megjegyezük későbbi elküldésre, ez egy potenciális Memory Leak, ha sokáig nem sikerül elküldeni
          let url = '/message/upload/' + uj.uuid;
          if (Array.isArray(f)) {
            url = '/message/uploaddir/' + uj.uuid;
          } else {
            f = [f];
          }
          console.log("felkell tölteni  a fájlt")
          for (let i = 0; i < f.length; i++) {
            var data = new FormData();
            data.append('file', f[i]);
            ret = await uploadFile(url, data);
            if (!uj.upload && ret) uj.upload = ret.upload;
          }
        }
        if (payload.jarat) {
          DATA.jarat.dispatch('sent', uj);
        }
        if (payload.km) DATA.pos.dispatch('set', payload.km);
        //  fetchJSON('POST', '/message/sendmail/' + uj.uuid);
        if (navigator.onLine) {
          setTimeout(checkmsg, 500);
        } else if (warn) {
          if (payload.rnev === 'sosalert') {
            warn('A RIASZTÁS NEM SIKERÜLT!\nKérjük ellenőrizze, hogy be van-e kapcsolva a mobil internet!\nA segélyhívás küldéséhez használja a lenti SMS gombot', {
              variant: 'error', anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              }, autoHideDuration: 10000
            });
          } else {
            warn('Kérjük ellenőrizze, hogy be van-e kapcsolva a mobil internet!\nAz üzenetet továbbítjuk a központba, amint az eszköz újra csatlakozik az internethez.', { variant: 'info', autoHideDuration: 7000 });
          }
        }
        break;
      }
      case 'valasz': {
        //    if( navigator.serviceWorker &&  navigator.serviceWorker.controller) navigator.serviceWorker.controller.postMessage({ type: 'NYUGTA', id: payload.hivatkozas });
        const orig = msg.find(u => u.sorszam === payload.hivatkozas);
        if (orig) orig.nysofor = sofor;
        return reducer(msg, { type: 'send', payload });
      }

      case 'add': {
        console.log("Send:", payload);
        if (!msg || !msg.length) msg = [];

        const van = msg.find(m => m.sorszam === payload.sorszam);
        if (van) {
          console.log("Már megkaptuk egy korábbi frissésnél");
        } else {
          const sort = msg.length && msg[msg.length - 1].stamp > payload.stamp;
          msg = [...msg, payload];
          if (sort) msg.sort((a, b) => {
            if (a.stamp > b.stamp) return 1;
            if (a.stamp < b.stamp) return -1;
            return 0;
          })
        }
        return msg;
      }

      case 'tportban': {
        return msg.map(m => {
          if (m.uuid === payload.uuid || m.sorszam === payload.sorszam) {
            m.sorszam = payload.sorszam;
            m.pending = false;
            if (m.file) delete m.file;
          }
          return m;
        })

      }
      default: break;
    }
    return msg;
  }
  return reducer;
}

const potkocsiReducer = async (val, { type, payload }) => {
  switch (type) {
    case 'felakaszt': {
      const { frsz, suly, mennyiseg, hofok } = payload;
      const info = DATA.potkocsilist.val.find(f => f.frsz === frsz);
      await send({ potkocsi: frsz, msg: frsz + ' pótkocsit felakasztottam', rnev: 'felakaszt', ertek: `frsz:${frsz}\nsuly:${suly}\nmennyiseg:${mennyiseg}\nhutes:${!!hofok}\nhofok:${hofok}` });
      return { ...payload, info, hutes: !!hofok };
    }
    case 'leakaszt': {
      console.log("leakasztás:", payload);
      const hol = payload.hol === 'itt' ? 'most' : 'korábban máshol';
      const { frsz, suly, mennyiseg, hutes, hofok } = val;
      await send({ potkocsi: frsz, msg: `${frsz} pótkocsit ${hol} leakasztottam. ${payload.txt || ''}`, rnev: payload.hol === 'itt' ? 'itthagyta' : 'leakaszt', ertek: `frsz:${frsz}\nsuly:${suly}\nmennyiseg:${mennyiseg}\nhutes:${hutes}\nhofok:${hofok}` });
      return {};
    }
    case 'set':
      return Object.assign({}, val, payload);

    case 'hutesbe': {
      const { hofok, hutoora } = payload;
      const { frsz, suly, mennyiseg } = val;
      await send({ potkocsi: frsz, msg: `${frsz} hűtését bekapcsoltam: ${hofok}°C`, rnev: 'hutesbe', ertek: `frsz:${frsz}\nsuly:${suly}\nmennyiseg:${mennyiseg}\nhofok:${hofok}\nhutoora:${hutoora}` });
      return { ...val, hutes: true, hofok, hutoora };
    }

    case 'huteski': {
      const { hutoora } = payload;
      const { frsz, suly, mennyiseg } = val;
      await send({ potkocsi: frsz, msg: `${frsz} hűtését kikapcsoltam`, rnev: 'huteski', ertek: `frsz:${frsz}\nsuly:${suly}\nmennyiseg:${mennyiseg}\nhutoora:${hutoora}` });
      return { ...val, hutes: false, hofok: '', hutoora };
    }
    default: break;
  }
  return val;
}

function degreesToRadians(d) { return d * Math.PI / 180; }

function distance(p1, p2) {
  if (!p1 || !p2 || !p1.coords || !p2.coords) return 0;
  let lat1 = p1.coords.latitude;
  let lon1 = p1.coords.longitude;
  let lat2 = p2.coords.latitude;
  let lon2 = p2.coords.longitude;

  var earthRadius = 6371; //KM

  var dLat = degreesToRadians(lat2 - lat1);
  var dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadius * c;
}


const emptyPos = { km: 0, kmpos: false, pos: false, legvonal: 0, szamlalo: 0, virtualkm: 0, min: 0, max: 2000000 };
const posReducer = (val, { type, payload }) => {
  console.log("posreducer", { val, type, payload });
  switch (type) {
    case 'set':
      const km = payload;
      return { km, kmpos: val.pos, pos: val.pos, legvonal: 0, szamlalo: 0, virtualkm: 0, min: km, max: km + 1 };
    case 'update':
      const szamlalo = val.szamlalo + distance(val.pos, payload);
      const legvonal = Math.floor(distance(val.kmpos, payload));
      return {
        km: val.km,
        kmpos: val.kmpos,
        pos: payload,
        legvonal,
        szamlalo,
        virtualkm: val.km + Math.ceil(szamlalo * 10) / 10,
        min: val.km + legvonal,
        max: val.km + Math.ceil(Math.max(legvonal * 1.7, szamlalo * 1.4)),
      };
    case 'reset':
      return emptyPos;
    default: break;
  }
  return val;
}

function setTetel(list, jarat, tetel, cb) {
  const idx = list.findIndex(j => j.pos === jarat);
  const j = list[idx];
  let t = j.tetel.find(t => t.sorszam === tetel);
  cb(t);
  j.lezarva = j.tetel.filter(t => t.sajat && !t.iteny).length === 0;
  list.splice(idx, 1, j);
  return [...list];
}

async function jaratReducer(val, { type, payload }) {
  console.log("jaratreducer", { val, type, payload });
  val = val || [];
  const { jarat, tetel, ido, km, suly, mennyiseg, plomba, zaropt, masiksofornek } = payload;
  switch (type) {
    case 'refresh':
      if (jarat) {
        console.log("FetchJarat");
        return await fetchJarat(jarat, val, DATA.msg.val);
      }
      return val;
    case 'indulas':
      DATA.potkocsi.dispatch('set', { suly, mennyiseg });
      let rakomany = [];
      if (suly) rakomany.push(`${suly}${Number(suly) > 100 ? 'kg' : 't'}`);
      if (mennyiseg) rakomany.push(`${mennyiseg}plt`);
      if (rakomany.length) {
        rakomany = `, rakomány: ${rakomany.join(', ')}`;
      } else {
        rakomany = '';
      }
      if (zaropt && plomba) {
        let zt = zaropt.find(({ value }) => value === plomba);
        if (zt) zt = zt.label;
        if (zt) {
          rakomany += ', ' + zt;
        }
      }
      return setTetel(val, jarat, tetel, t => {
        t.iteny = ido;
        t.steny = suly;
        t.menny = mennyiseg;
        send({
          msg: `${ido.substr(11, 5)}-kor elindultam ${t.pdisplay} ${['', 'felrakásról', 'átakasztásról', 'átakasztásról', 'lerakásról', 'via pontról', '', '', ''][t.jelleg]}${rakomany}`,
          jarat, tetel, rnev: 'indulas', ertek: `ido:${ido}\nkm:${DATA.pos.val.km}\nsuly:${suly || 0}\nmennyiseg:${mennyiseg || 0}\nplomba:${plomba}`
        });

        return t;
      })
    case 'erkezes':
      if (km) DATA.pos.dispatch('set', km);
      return setTetel(val, jarat, tetel, t => {
        t.eteny = ido;
        t.km = km;
        if (t.csakerkezes) t.iteny = t.eteny;
        send({
          msg: `${ido.substr(11, 5)}-kor megérkeztem ${t.pdisplay} ${['', 'felrakásra', 'átakasztásra', 'átakasztásra', 'lerakásra', 'via pontra', '', '', ''][t.jelleg]}`,
          km, jarat, tetel, rnev: 'erkezes', ertek: `ido:${ido}\nkm:${km}\nsuly:${DATA.potkocsi.val.suly || 0}\nmennyiseg:${DATA.potkocsi.val.mennyiseg || 0}\njelleg:${t.jelleg}\nmasiksofornek:${masiksofornek}`
        });

        return t;
      })
    case 'sent':
      try {
        console.log("sent message on járat", { upload: payload.upload, ertek: payload.ertek });
        if (payload.upload && payload.ertek.startsWith('tipus:1')) {
          const j = val.find(j => j.pos === jarat);
          j.crmsent = true;
          console.log("sent CMR", j);
          return [...val];
        }

        if (payload.upload && payload.ertek.startsWith('tipus:2')) {
          const j = val.find(j => j.pos === jarat);
          j.menetlevelsent = true;
          return [...val];
        }

        if (payload.upload && payload.ertek.startsWith('tipus:3')) {
          const j = val.find(j => j.pos === jarat);
          j.plombasent = true;
          return [...val];
        }

        if (payload.rnev === 'jegyzokonyv') {
          const j = val.find(j => j.pos === jarat);
          let jkonyv = payload.ertek.split('\n')[0];
          switch(jkonyv){
            case 'Hűtőlap':
              j.hutolapsent = true;
              break;
            case 'TAPA indulás':
              j.tapa1sent = true;
              break;
            case 'TAPA megrakva':
              j.tapa2sent = true;
              break;
            case 'TAPA naponta':
              j.tapa3sent = true;
              break;
            default: break;
          }
          return [...val];
        }        
      } catch (e) {
        console.log(e.stack);
      }
      break;
    case 'nofrigo':
      return setTetel(val, jarat, tetel, t => {
        t.frigo = false;
        return t;
      })
    default: break;

  }
  return val;
}


async function pihenoReducer(val, { type, payload }) {
  switch (type) {
    case 'start':
      return payload; //{ kezdet, ora }
    case 'end':
      if(payload && payload.ido){
        let {ido, vantizenot} = payload;
        await send({
          msg: `Műszak kezdete ${ido.substr(11, 5)} ${vantizenot? ', még van 15-ös műszakom': ', már nincs 15-ös műszakom'}`,
          rnev: 'muszakkezdet', ertek: `ido:${ido}\nvantizenot:${vantizenot}`
        });
      }
      return false;
    default: break;

  }
  return val;
}

const ciklusReducer = async (val, { type, payload }) => {
  switch (type) {
    case 'setvege': {
      if (payload) {
        return {
          vegejelzes: {
            terv: payload,
            datum: payload,
            offset: 0
          }, tport: payload
        }
      } else {
        return { ...val, tport: false }
      }
    }
    case 'checkvege': {
      const vege = await fetchJSON('GET', '/tport/ciklusvege');
      if (vege === val.tport) return val;

      if (vege) {
        //  console.log("van vége",vege);
        return {
          vegejelzes: {
            terv: vege,
            datum: vege,
            offset: 0
          }, tport: vege
        }
      } else {
        return { ...val, tport: false }
      }
    }
    case 'cancel':
      return { ...val, vegejelzes: {} };
    case 'ciklusvege': {
      let { offset, datum, txt, terv } = payload;
      // console.log('ciklusvege',payload);
      let otxt = 'terv szerint';
      let nap = datum.format('yyyy.MM.DD. dddd ');
      datum = dateFromMoment(datum);
      terv = dateFromMoment(terv);
      if (offset > 0) {
        otxt = `${offset} nappal terv után`;
      } else if (offset < 0) {
        otxt = `${0 - offset} nappal terv elött`;
      }
      await send({
        msg: `Munkaciklusom vége ${nap}, ${otxt}. ${txt || ''}`,
        rnev: 'ciklusvege', ertek: `datum:${datum}\noffset:${offset}\nterv:${terv}\n${txt || ''}`
      });
      return {
        ...val, vegejelzes: {
          offset, datum, terv, txt
        }
      };
    }

    case 'cikluskezdet': {
      let { offset, datum, txt, terv } = payload;
      //  console.log('ciklusvege',payload);
      let otxt = 'terv szerint';
      let nap = datum.format('yyyy.MM.DD. dddd ');
      datum = dateFromMoment(datum);
      terv = dateFromMoment(terv);
      if (offset > 0) {
        otxt = `${offset} nappal terv után`;
      } else if (offset < 0) {
        otxt = `${0 - offset} nappal terv elött`;
      }
      await send({
        msg: `Következő munkaciklusom első napja ${nap}, ${otxt}. ${txt || ''}`,
        rnev: 'cikluskezdet', ertek: `datum:${datum}\noffset:${offset}\nterv:${terv}\n${txt || ''}`
      });
      return {
        ...val, kezdetjelzes: {
          offset, datum, terv, txt
        }
      };
    }

    case 'vantizenot':{
      return {...val, vantizenot: payload};
    }
    
    default: return val;
  }
}

async function sosReducer(val, { type, payload }) {
  switch (type) {
    case 'countdown':
      return { alerted: 0, start: now() };

    case 'alert':
      let pos = 'ISMERETLEN';
      if (DATA.pos.val && DATA.pos.val.pos) {
        let { timestamp, coords } = DATA.pos.val.pos;
        if (coords) {
          pos = `${timestamp} - ${coords.latitude}, ${coords.longitude}`;
        }
      }
      await send({
        msg: `S.O.S segélyhívás! ${DATA.sofor.val.rnev} ${DATA.config.val.kocsi} GPS: ${pos}`,
        rnev: 'sosalert', ertek: payload
      });
      return { alerted: (val.alerted || 0) + 1, last: now(), start: val.start };

    case 'clear':
      if (val.test) {
        send({
          msg: `Segélyhívás teszt LEMONDVA`,
          rnev: 'sostest', ertek: payload
        });
        console.log("cleared sos");
      } else {
        send({
          msg: `Segélyhívás LEMONDVA`,
          rnev: 'sosclear', ertek: payload
        });
        console.log("cleared sos");
      }
      return false;

    case 'test':
      await send({
        msg: `Segélyhívás TESZT`,
        rnev: 'sostest', ertek: ``
      });
      return { alerted: (val.alerted || 0) + 1, test: now(), start: val.start };
    default: return val;
  }
}



export const DataContext = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  useVal({
    key: 'config', defval: localStorage.getItem('config') || (demo ? { id: 10, kocsi: "RRC808", naplozas: 5 } : {}), onChange: (v) => {
      localStorage.setItem('config', v);
      setEletjel(v.eletjel);
    }
  });
  useVal({ key: 'token', defval: localStorage.getItem('token'), onChange: (v) => localStorage.setItem('token', v) });
  useVal({ key: 'sofor' });
  useVal({ key: 'msg', reducer: msgReducer(enqueueSnackbar), defval: [] });
  useVal({ key: 'potkocsilist' });
  useVal({ key: 'potkocsi', reducer: potkocsiReducer, defval: {} });
  useVal({ key: 'jarat', reducer: jaratReducer, defval: [] });
  useVal({ key: 'aktivjarat' });
  useVal({ key: 'piheno', reducer: pihenoReducer });
  useVal({ key: 'pos', defval: emptyPos, reducer: posReducer });
  useVal({ key: 'trancard', defval: [] });
  useVal({ key: 'sablon', defval: [] });
  useVal({ key: 'urlap', defval: {} });
  useVal({ key: 'storage' });
  useVal({ key: 'eurowag', defval: {}, reducer: eurowagReducer });
  useVal({ key: 'ciklus', defval: {}, reducer: ciklusReducer });
  useVal({ key: 'sos', defval: false, reducer: sosReducer });
  
  console.log("DataContext render");
  updateStorage();
  checkEletjel();
  checkEurowag(DATA.eurowag, enqueueSnackbar);

  return <DataCtx.Provider value={{ ...DATA }}>{children}</DataCtx.Provider>
}

export function loadidb() {
  Object.values(DATA).map(v => v.refresh());
}

export const useData = (key) => useContext(DataCtx)[key];
export const useValue = (key) => useContext(DataCtx)[key].val;
export const useDispatch = (key) => useContext(DataCtx)[key].dispatch;
export const useSave = (key) => useContext(DataCtx)[key].save;

export const useAktivJarat = () => {
  const list = useValue('jarat');
  const a = useValue('aktivjarat');
  const select = useSave('aktivjarat');
  const jarat = list && list.find(j => j.pos === a);
  return { jarat, select };
}

export const useDataState = () => {
  useContext(DataCtx);
  return Object.keys(DATA).reduce((acc, k) => { acc[k] = DATA[k].val; return acc; }, {});
}


export const useAuth = () => {
  const { sofor, token, trancard, potkocsi, msg, jarat, aktivjarat, eurowag, ciklus, piheno } = useContext(DataCtx);
  const login = async (val) => {
    await sofor.save(val.sofor);
    await token.save(val.token);
    await ciklus.dispatch('setvege', val.vege);
    await ciklus.dispatch('vantizenot', true);

    await loadtport();

    loadidb();
    // await ciklus.dispatch('checkvege');
  };

  const logout = async () => {
    const l = sofor.val + ' kijelentkezett';
    await potkocsi.save({});
    await msg.save([]);
    await jarat.save([]);
    await aktivjarat.save(false);
    await sofor.save(false);
    await token.save(false);
    await trancard.save([]);
    await eurowag.save({});
    await ciklus.save({ tport: false });
    await piheno.save(false);
    mindig(l);
  };

  return { login, logout };
}

export function usePositionTracker(interval = 10000) {
  const pos = useValue('pos');
  const dispatch = useDispatch('pos');
  const [current, setCurrent] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (interval) {
      let lastCall = false;
      let timeout = false;
      const storePos = (p) => {
        const { latitude, longitude, accuracy, speed, heading } = p.coords;
        let timestamp = moment(new Date(p.timestamp)).format('YYYY-MM-DD HH:mm:ss');
        let domts = p.timestamp;
        let val = { coords: { latitude, longitude, accuracy, speed, heading }, timestamp, domts };
        dispatch('update', val);
      }

      const id = navigator.geolocation.watchPosition((p) => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = false;
        }
        if (!lastCall) { //most futunk rá először, ne hívjuk meg, nehogy kinullázza a km-t.
          lastCall = new Date();
        } else {
          if (new Date() - lastCall > interval) {
            lastCall = new Date();
            storePos(p);
          } else {
            timeout = window.setTimeout(() => storePos(p), interval - (new Date() - lastCall));
          }
        }
      }, setError, { maximumAge: 10000, enableHighAccuracy: true });
      return () => {
        console.log("clearWatch");
        navigator.geolocation.clearWatch(id);
      }
    }
  }, [interval, setError, setCurrent, dispatch])

  return { current, pos, error };
}

export async function loadtport() {
  console.log("loadtport");
  try {
    const config = await fetchJSON('POST', '/tport/config');
    if (config && config.id) {
      console.log("save config", config);
      DATA.config.save(config);
      console.log("config saved");
    }
  } catch (e) {
    console.log("config fetch failed", e.stack);
  }
  console.log("loadtport end");

  const msg = await fetchJSON('GET', '/message/naplo');
  console.log(msg);
  if (msg && !msg.error && msg.filter) {
    DATA.msg.save(msg);
    DATA.jarat.save([]);
    let poslist = msg.filter(m => m.sendtour && m.jarat).reduce((acc, m) => { //ezeket a járatokat küldték le, kérjük el az aktuális állapotot (egyszer)
      acc[m.jarat] = m;
      return acc;
    }, {});
    const jlist = await fetchJSON('GET', '/jarat/list');
    if (jlist && jlist.length) {
      jlist.map(pos => poslist[pos] = pos);
    }

    const j = Object.keys(poslist);
    for (let i = 0; i < j.length; i++) {
      await DATA.jarat.dispatch('refresh', { jarat: j[i] });
    }
  }

  const trancard = await fetchJSON('GET', '/tport/trancard');
  console.log(trancard);
  if (trancard && !trancard.error) {
    DATA.trancard.save(trancard);
  }

  const sablon = await fetchJSON('GET', '/tport/sablon/' + DATA.sofor.val.nyelv);
  console.log("sablon", sablon);
  if (sablon && !sablon.error) {
    DATA.sablon.save(sablon);
  }


  const urlap = await fetchJSON('GET', '/tport/urlap');
  console.log("urlap", urlap);
  if (urlap && !urlap.error) {
    DATA.urlap.save(urlap);
  }
}

export async function checkmsg() {
  console.log("checkmsg");
  if (!navigator || !navigator.onLine) {
    console.log("Nincs net");
    return;
  }
  if (!DATA.sofor.val) {
    console.log("Nincs bejelentkezve");
    return;
  }
  let msg = await fetchJSON('POST', '/message/checkmsg');
  console.log(msg);
  if (msg && !msg.error) {
    if (msg.outdated) {
      console.log("verziót kellene frissíteni: ", msg);
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        console.log("van service workerünk: ", navigator.serviceWorker);
        navigator.serviceWorker.controller.postMessage({ type: 'UPDATE_VERSION' });
      }
    }
    if (msg.list) msg = msg.list;
    if (msg.length) {
      for (let i = 0; i < msg.length; i++) {
        let m = msg[i];
        if (m.irany === 2) {
          DATA.msg.dispatch('add', m);
          if (m.sendtour && m.jarat) {
            await DATA.jarat.dispatch('refresh', m);
          }
          await fetchJSON('POST', '/message/feedback', { sorszam: m.sorszam, op: 'letoltve', uzenet: 'letoltve' })
        } else {
          DATA.msg.dispatch('tportban', m);
        }
      }
    }
  }
}

export function updateStorage() {
  if ("storage" in navigator && "estimate" in navigator.storage) {
    navigator.storage.estimate().then(ret => set('storage', ret));
  }
}


export async function resend() {
  console.log("resend");
  if (DATA && DATA.msg && DATA.msg.val && DATA.msg.val.length && navigator && navigator.onLine) {
    let pending = DATA.msg.val.filter(m => (m.irany === 1 && m.pending));
    console.log("pending", pending);
    for (let i = 0; i < pending.length; i++) {
      const uj = pending[i];
      let ret = await fetchJSON('POST', '/message', uj);
      console.log("resending:", ret);
      if (!ret || !ret.sorszam) break; //nincs értelme folytatni.
      let f = uj.file;
      if (f) {
        let url = '/message/upload/' + uj.uuid;
        if (Array.isArray(f)) {
          url = '/message/uploaddir/' + uj.uuid;
        } else {
          f = [f];
        }
        console.log("felkell tölteni  a fájlt")
        for (let i = 0; i < f.length; i++) {
          var data = new FormData();
          data.append('file', f[i]);
          ret = await uploadFile(url, data);
          if (!uj.upload) pending[i].upload = ret.upload;
        }
      }
    }
    if (pending.length) await checkmsg();
  }
}

export async function syncMsg() {
  await checkmsg();
  await resend();
}