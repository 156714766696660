import { Grid, LinearProgress } from '@material-ui/core';
import React from 'react';
import { useDispatch, useValue } from '../data';
import { fetchJSON, now } from '../swutils';
import { stampToMoment } from '../../utils.mjs';
import moment from 'moment';
import config from '../../config.json'

let cnt = 0;
let timer = null;
let data = {};
let snackbar = null;
let timeout = config.eurowag.timeout;

async function check() {
  const { val, dispatch } = data;
  if (!val || !dispatch) return;
  let { kartya, started, unlocked } = val;
//  console.log("checkEurowag", val);
  if (kartya && started && !unlocked) {
    try {
      const st = await fetchJSON('GET', '/eurowag/' + kartya);
      if (st && !st.error && st.bejovo) {
        dispatch('unlocked', st.bejovo);
        cnt = 0;
        return;
      }
    } catch (e) {
    }
    if (cnt++ > config.eurowag.retry) {
      dispatch('timeout');
      cnt = 0;
    }
  }
  if (kartya && unlocked) {
    console.log("diff", moment().diff(stampToMoment(val.unlocked)));
    const timeleft = timeout - Math.floor(moment().diff(stampToMoment(val.unlocked)) / 60000);
    console.log("timeleft", timeleft);
    if (timeleft > 0) {
      dispatch('timeleft', timeleft);
    } else {
      dispatch('locked');
    }
  }
}

export function checkEurowag(d, enqueueSnackbar) {
  data = d;
  snackbar = enqueueSnackbar;
  if (!timer) {
    console.log("start eurowag check");
    timer = setInterval(() => check(), config.eurowag.checkinterval * 1000);
  }
}


export async function eurowagReducer(val, { type, payload }) {
  switch (type) {
    case 'setkartya':
      return { ...val, kartya: payload };
    case 'unlock':
      if (!navigator.onLine) {
        if (snackbar) snackbar('Az Eurowag kártya aktiváláshoz internet kapcsolat kell! Ellenőrizze a mobil internetet.', { variant: 'error', autoHideDuration: 10000 });
      } else {
        try {
          await fetchJSON('POST', '/eurowag/' + payload);
          return { ...val, kartya: payload, started: now(), unlocked: false };
        } catch (e) {
          if (snackbar) snackbar('Az Eurowag kártyát az alklamazáson keresztül jelenleg nem lehet aktiválni!', { variant: 'error', autoHideDuration: 10000 });
        }
      }
      return val;
    case 'unlocked':
      if (snackbar) snackbar('Az Eurowag kártyát sikeresen aktiváltuk!', { variant: 'info' });
      return { ...val, unlocked: payload || now(), timeleft: timeout };
    case 'timeleft':
      if (!payload) return { ...val, started: false, unlocked: false };
      return { ...val, timeleft: payload };
    case 'timeout':
      if (snackbar) snackbar('Az Eurowag kártyát nem tudtuk aktiválni!', { variant: 'error' });
      return { ...val, started: false, unlocked: false, timeleft: 0 };
    case 'locked':
      return { ...val, started: false, unlocked: false, timeleft: 0 };

    default: break;
  }
  return val;
}


export const Eurowag = () => {
  let { started, kartya, unlocked, timeleft } = useValue('eurowag');
  let dispatch = useDispatch('eurowag');
  let trancard = useValue('trancard');
  if (!trancard.length) return null;
  //  if (!kartya) {
  trancard = trancard.filter(c => c.tipus === 12);
  if (!trancard.length) return null;
  kartya = trancard[0].kartyaszam.replace(/[^0-9]+/g, '');
  //  }
  console.log("van eurowag:", kartya);
  let color = "#FFFFFF";
  let txt = "Eurowag";
  let img = "eurowag.svg";
  let onClick = null;
  if (!started) {
    onClick = () => dispatch('unlock', kartya);
  }
  if (started && !unlocked) {
    color = config.color.primary;
    img = "eurowag_waiting.svg";
    onClick = () => check();
  }
  if (unlocked) {
    txt = `${timeleft} perc`;
    color = "#B6FF00";
    onClick = () => dispatch('unlock', kartya);
    img = "eurowag_unlocked.svg";
  }
  return <Grid item xs={6} ms={4} onClick={onClick} style={{ textAlign: 'center', color }}><div><img src={img} alt="Eurowag" /></div>
    <div>{txt}</div>{started && !unlocked && <LinearProgress />}</Grid>

}

