import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import AppFrame from './App';
import { WindowDimensionsProvider } from '../rf/Responsive';
import moment from 'moment';
import 'moment/locale/hu';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { AppContext } from "../hooks.js";
import { SnackbarProvider } from 'notistack';
import { DataContext } from '../data';
import config from '../../config.json'

require('typeface-roboto-multilang/latin-ext.css');

moment.locale('hu');

const theme = createMuiTheme({
  overrides: {
    MuiInput: { // Name of the component ⚛️ / style sheet
      /*      underline: {
              '&:before': {
                borderBottom: '0px'
              },
            },
            '&$disabled:before': {
              borderBottom: '0px',
            },
      
            '_disabled': {
              color: '#333',
            },*/
    },
    MUIPaper: {
      root: { color: '#000' },
    },
    MuiIconButton: { root: { color: 'inherit' } },
    MuiListSubheader: { root: { color: 'inherit' } },
    MuiListText: { root: { color: 'inherit' } },
    MuiListIcon: { root: { color: 'inherit' } },
    MuiTypography: {
      h5: { color: 'inherit' },
      body1: { color: 'inherit' },
      button: { color: 'inherit' },
      subtitle1: { color: 'inherit', lineHeight: 1.25 },
      //body2: {lineHeight: 1},
    },
    MuiToggleButton: {
      root: {
        '&$selected': {
          backgroundColor: `${config.color.primary} !important`,
        },
      }
    },
  },
  palette: {
    type: 'dark',
    primary: { main : config.color.primary },
    secondary: { main: config.color.secondary },
  }
});



function App() {
  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <WindowDimensionsProvider>
            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} maxSnack={3}>
              <AppContext>
                <DataContext>
                  <AppFrame />
                </DataContext>
              </AppContext>
            </SnackbarProvider>
          </WindowDimensionsProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
