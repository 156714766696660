import React from 'react';
import { BottomNavigation, BottomNavigationAction, Divider, List, ListItem, ListItemIcon, makeStyles, Typography } from '@material-ui/core';
import { useValue } from '../data';
import { stampToMoment } from '../../utils.mjs';
import { useDrawer } from '../hooks';
import { TimeLine } from './TimeLine';
import { FuvarAction } from './FuvarAction';
import { Chat, ArrowDownward, ArrowUpward, Directions, DirectionsBus, Hotel, Info,  Queue } from '@material-ui/icons';
import { Link } from '../rf/Link';
import config from '../../config.json'

function GpsCoords({ txt }) {
  if(!txt) return null;
  try {
    let crd = txt.replace(/[,;]/g, '.').replace(/\s*/g, '').split('.');
    return <Link style={{ display: 'inline' }} addr={crd[0] + '.' + crd[1] + ',' + crd[2] + '.' + crd[3]}>{txt}</Link>
  } catch (e) {
    console.log(e.stack);
    return txt;
  }
}
// eslint-disable-next-line
const gps_rx = /([-+]?)([\d]{1,2})((([\.,])(\d+)(\s*)([,;])))(\s*)(([-+]?)([\d]{1,3})(([\.,])(\d+))?)/g
function FindCoords({ txt }) {
  if(!txt) return null;
  const m = txt.match(gps_rx);
  if (m) {
    const ret = [];
    for (let i = 0; i < m.length && txt; i++) {
      let t = txt.split(m[0]);
      ret.push(<span key={i}>{t[0]} <GpsCoords txt={m[i]} /></span>);
      txt = t[1];
    }
    if (txt) {
      ret.push(<span key="vege">{txt}</span>);
    }
    return ret;
  }
  return txt;
}

const useStyles = makeStyles(theme => ({
  section: {
    width: '100%',
    padding: 4,
  },
  icon: {
    float: 'left',
  }

}));

function FuvarDesc({ f }) {
  const classes = useStyles();
  if (!f) return null;
  const { msg, ttxt1, ttxt2, ttxt3, ttxt4, lpihi1, lpihi2, lpihi3, pottxt } = f;
  let li = [
    { Icon: Chat, txt: msg },
    { Icon: DirectionsBus, txt: `${pottxt}` },
    /* { Icon: AirlineSeatReclineNormal, txt: `${sofor}${sofor2 ? ' + ' + sofor2 : ''}`}*/
  ];
  if (ttxt1) li.push({ Icon: ArrowUpward, txt: ttxt1 });
  if (ttxt2) li.push({ Icon: Queue, txt: ttxt2 });
  if (ttxt3) li.push({ Icon: ArrowDownward, txt: ttxt3 });
  if (ttxt4) li.push({ Icon: Info, txt: ttxt4 });
  if (lpihi1) li.push({ Icon: Hotel, txt: lpihi1 });
  if (lpihi2) li.push({ Icon: Hotel, txt: lpihi2 });
  if (lpihi3) li.push({ Icon: Hotel, txt: lpihi3 });
  return <List>
    {
      li.map(({ Icon, txt }, i) => <ListItem key={i} divider>
        <ListItemIcon><Icon className={classes.icon} /></ListItemIcon>
        <FindCoords txt={txt} />
      </ListItem>)
    }
  </List>
}

export function FuvarDetails(props) {
  const aktivjarat = useValue('aktivjarat');
  const list = useValue('jarat');
  const { page, openDrawer } = useDrawer();
  console.log('Fuvar', { props, aktivjarat, list });
  let { jarat } = props;
  if (!jarat) jarat = page && page.jarat && page.jarat.jarat;
  if (!jarat) jarat = aktivjarat;
  const p = (page && page.jarat && page.jarat.id) || 'info';

  if (!jarat && list && list.length) {
    const al = list.filter(f => {
      if (!f || f.lezarva || f.lemondva || !f.sajat || !f.tetel || !f.tetel.length) return false;
      if (!f.sajattetel) f.sajattetel = f.tetel.filter(t => t.sajat);
      if (!f.sajattetel.length) return false;
      return true;
    });

    if (al.length) jarat = al[0].pos;
  }
  console.log('Fuvar', { jarat });
  if (!jarat) return null;

  const f = list.find(j => j.pos === jarat);
  console.log("f", f);
  if (!f) return null;
  let { pos, viszonylat, tetel, fuvar } = f;
  if (fuvar && fuvar.mref) viszonylat = fuvar.mref;
  if (!viszonylat) viszonylat = tetel.map(t => t.pdisplay).join(' - ');
  let st = stampToMoment(tetel[0].eterv);
/*  let txt = null;
  if (fuvar) {
    txt = <FuvarDesc f={fuvar} />;
  } else {
    txt = f.msg && f.msg.map(m => <div key={m.sorszam}><div style={{ textAlign: 'right', color: '#bbb' }}>{format.fromNow(m.created)}</div> {m.msg} <hr /></div>);
  }*/

  return <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', position: 'relative',overflow:'hidden' }}>
    <Typography variant="subtitle1" style={{ position: 'relative', textAlign: 'center', background: config.color.primary, padding: 16 }}>
      {pos} | {st.format('YYYY.MM.DD ddd')}<br />
      {viszonylat}
      <FuvarAction f={f} style={{ position: 'absolute', bottom: -24, right: 20, zIndex: 1000 }} />
    </Typography>
      <BottomNavigation
       style={{ paddingRight: '30%' }}
        value={p}
        onChange={(event, value) => { openDrawer('jarat', { ...page.jarat, id: value }, 'replace')(); }}
        showLabels
      >
        <BottomNavigationAction icon={<Chat />} label="Leírás" value="info" />
        <BottomNavigationAction icon={<Directions />} label="Útvonal" value="utvonal" />
      </BottomNavigation>
    <div style={{ padding: 0, paddingBottom:150, flexGrow: 100, overflowY: 'auto' }}>
      {p === 'msg' && <><div style={{ marginBottom: 8 }}><FindCoords txt={fuvar.msg} /></div><Divider /><div style={{ marginTop: 8 }}><FindCoords txt={fuvar.feladat} /></div></>}
      {p === 'info' && <FuvarDesc f={fuvar} />}
      {p === 'utvonal' && <><div style={{ margin: 8 }}><FindCoords txt={fuvar.leiras} /></div><Divider /><div style={{ margin: 8 }}><TimeLine tetel={tetel} /></div></>}      
    </div>
    <div style={{
        height: 120, position: 'relative', top: -120, marginBottom: -120,
        background: 'linear-gradient(0deg, rgba(66,66,66,1), rgba(66,66,66,0))'
      }}></div>


  </div>
}