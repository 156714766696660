import React, { useState, useEffect } from 'react';
import { Button, List, ListItem, ListItemSecondaryAction, ListItemText, ListItemIcon, Divider, Link } from '@material-ui/core';
import { LocalShipping, Phone, AirlineSeatReclineNormal, DirectionsBus, AccessTime, AcUnit, Description, YouTube, Event, Edit } from '@material-ui/icons';
import { useDialog } from '../rf/Dialog';
import { useDispatch, useValue } from '../data';
import { useDrawer } from '../hooks';
import { now } from '../swutils';
import { NextFuvar } from '../fuvar/NextFuvar';
import { ErrorBoundary } from '../app/ErrorBoundary';

function Ido() {
  const [, setIdo] = useState(new Date());
  useEffect(() => {
    const i = setInterval(() => setIdo(new Date()), 1000);
    return () => clearInterval(i);
  }, [])
  return <ListItemText primary={now()} />
}

export function PotIcon() {
  return <div style={{ position: 'relative' }}>
    <LocalShipping />
    <div style={{ backgroundColor: '#000', position: 'absolute', top: 0, bottom: 0, right: 0, width: 7 }}></div>
  </div>
}

export function PotStatus() {
  const { info, suly, hutes, hofok, mennyiseg } = useValue('potkocsi');
  const { setDialog } = useDialog();
  const huto = info && info.pottipus === 1;
  let aru = [];
  if (suly && suly < 40) aru.push(`${suly}t`);
  if (mennyiseg) aru.push(`${mennyiseg}plt`);
  if (hutes) {
    aru.push(' FRIGO');
    aru.push(` ${hofok}°C`);
  }
  if (!aru.length && !huto) return null;

  return <ListItem>
    <ListItemIcon>{hutes ? <AcUnit /> : <Description />} </ListItemIcon>
    <ListItemText primary={aru.join(" / ")} />
    {huto && <ListItemSecondaryAction>{hutes ?
      <Button color="primary" variant="contained" onClick={() => setDialog({ id: 'huteski' })}>HŰTÉS KI</Button> :
      <Button color="primary" variant="contained" onClick={() => setDialog({ id: 'hutesbe' })}>HŰTÉS BE</Button>
    }
    </ListItemSecondaryAction>
    }
  </ListItem>
}

export function Valtas() {
  const valtas = useValue('ciklus');
  const dispatch = useDispatch('ciklus');
  const { setDialog } = useDialog();  
  const refreshValtas = async function(){
    await dispatch('checkvege');
  }

  let vege =  'Még nincs rögzítve';
  if(valtas){
    if(valtas.tport){
      vege = valtas.tport;
    }else if(valtas.vegejelzes && valtas.vegejelzes.datum){
      vege = `${valtas.vegejelzes.datum} jóváhagyás alatt`;
    }
  }
  return <ListItem>
  <ListItemIcon><Event/></ListItemIcon>
  <ListItemText primary={`Hazaérkezés: ${vege}`} secondary="ide kattintva lekérdezheti" onClick={refreshValtas}/>
  <ListItemSecondaryAction>
    <Edit onClick={()=>setDialog({id: 'ciklusvege'})}/>
  </ListItemSecondaryAction>
</ListItem>
}



export function SoforStatus() {
  const potkocsi = useValue('potkocsi');
  const sofor = useValue('sofor');
  const { setDialog } = useDialog();
  const config = useValue('config');
  const { openDrawer } = useDrawer();
  const hiba = (txt) => openDrawer('send', { id: 'txt', txt })
  const Nemjo = ({ txt }) => <ListItemSecondaryAction onClick={hiba(txt)}><small>Nem stimmel?</small></ListItemSecondaryAction>

  return <ErrorBoundary component="SoforStatus"><List>
    <ListItem>
      <ListItemIcon><AirlineSeatReclineNormal /></ListItemIcon>
      <ListItemText primary={sofor && sofor.nev} />
      <Button variant="outlined" onClick={()=>setDialog({id:'logout'})}>Kijelentkezés</Button>
    </ListItem>
    <ListItem>
      <ListItemIcon><Phone /></ListItemIcon>
      <ListItemText primary={sofor && sofor.tel} />
      <Nemjo txt={"A rendszerben rosszul van megadva a telefon számom!\nA helyes szám: "} />
    </ListItem>
    <Divider />
    <ListItem>
      <ListItemIcon><DirectionsBus /></ListItemIcon>
      <ListItemText primary={config && config.kocsi} />
      <Nemjo txt={"Hibásan van beállítva a programban a vontató rendszáma!\nA helyes rendszám ez: "} />
    </ListItem>
    <ListItem>
      <ListItemIcon><PotIcon /></ListItemIcon>
      <ListItemText primary={potkocsi.frsz || 'Nincs pót!'} secondary={potkocsi.info ? potkocsi.info.tipus : ''} />
      <ListItemSecondaryAction>{potkocsi.frsz ?
        <Button color="primary" variant="contained" onClick={() => setDialog({ id: 'leakasztas' })}>LEAKASZTÁS</Button> :
        <Button onClick={() => setDialog({ id: 'felakasztas' })} color="primary" variant="contained">FELAKASZTÁS</Button>}
      </ListItemSecondaryAction>
    </ListItem>
    <PotStatus />
    <Divider />
    <ListItem>
      <ListItemIcon><AccessTime /></ListItemIcon>
      <Ido />
      <Nemjo txt="A telefon órája rosszul jár!" />
    </ListItem>
    <Divider />
    <Valtas />
    

  </List>
  <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Link
            href="https://www.youtube.com/playlist?list=PLlS_-gJEoOLZGZvNQa9VNDzjgffeM_gu6"
            target="_blank">
            <YouTube fontSize="large" style={{verticalAlign:"bottom"}}/> Bevezetés a program használatába
          </Link>
        </div>

    <NextFuvar />
  </ErrorBoundary>

}