import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik'
import { TextField } from '../rf/input/Text';
import { buildNr, version, fetchJSON } from '../swutils'
import { Link, TextField as MUITextField } from '@material-ui/core';
import { useAuth, useDispatch, useValue } from '../data';
import { demo } from '../hooks';
import { mindig } from '../log';
import { YouTube } from '@material-ui/icons';
import { useDialog } from '../rf/Dialog';
import config from '../../config.json'

const styles = theme => ({
  full: { display: 'flex', height: '100vh' },
  main: {
    margin: 'auto',
    maxWidth: 300,
    maxHeight: 400,
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  logo: {
    margin: '1em',
    marginTop: '2em',
  },

  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  button: {
    width: '100%',
  },
  remember: {
    margin: '0px 1em',
  }
});

export const Login = withStyles(styles)((props) => {
  const { login } = useAuth();
  const { kocsi, id } = useValue('config');
  const [jogsi, setJszam] = useState('');
  const dispatchPos = useDispatch('pos')
  let { setDialog } = useDialog();

  const [error, setError] = useState('');
  const { classes } = props;

  const jszamChanged = (ev) => {
    setJszam(ev.target.value);
  }

  const onSubmit = async ({ jszam, kmora }) => {
    setError('');
    if (!kmora) {
      setError('A km megadása kötelező');
      return;
    }
    if (jszam || jogsi) {
      try {
        const res = await fetchJSON('POST', '/belepes', { jszam: jszam || jogsi, kocsi, eszkoz: id, kmora });
        if (res && res.sofor && res.token) {
          await login(res);
          await dispatchPos('set', kmora);    
          mindig('login');

          if(!res.vege){
            setDialog({ id: 'ciklusvege' });
          }

        } else {
          console.log("login error", res);
          setError((res && res.error) || 'Nem sikerült a bejelentkezés');
        }
      } catch (e) {
        console.log("login error", e);
        console.log("login error", e.stack);
        setError(e);
      }
    }
  }

  return (
    <div className={classes.full}>
      <div className={classes.main}>
        <div className={classes.logo}>
          <img src={(config.brand && config.brand.logo) || "logo.png"} width='100%' height='auto' alt={(config.brand && config.brand.caption) || "Prevost Hungária"} />
        </div>

        <Formik initialValues={{ jszam: '', kmora: '' }} onSubmit={onSubmit}>
          <Form>
            <div className={classes.form}>
              <div className={classes.input}>
                {demo ? <TextField
                  name="jszam"
                  label='Jogosítvány száma'
                  type="text"
                /> :
                  <MUITextField
                    fullWidth
                    label='Jogosítvány száma'
                    type="text"
                    onChange={jszamChanged}
                  />}
              </div>
              <div className={classes.input}>
                <TextField
                  name="kmora"
                  label='Km óra állása'
                  type="number"
                />
              </div>
              <div style={{ color: 'red' }}>{String(error)}</div>
            </div>
            <CardActions>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                className={classes.button}
              >
                Belépés
              </Button>
            </CardActions>
          </Form>
        </Formik>
        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Link
            href="https://www.youtube.com/playlist?list=PLlS_-gJEoOLZGZvNQa9VNDzjgffeM_gu6"
            target="_blank">
            <YouTube fontSize="large" style={{ verticalAlign: "bottom" }} /> Bevezetés a program használatába
          </Link>
        </div>
        <div style={{ fontSize: 10,position: 'fixed', bottom: 10, left: 0, right: 0, opacity: 0.4, textAlign: 'center' }}>{`[ t d r i v e r ] ${version} | ${buildNr}`}</div>
      </div>
    </div>);
})

export default Login;