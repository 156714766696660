import React, { useState, useCallback, useEffect } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Button, ListItemSecondaryAction, IconButton, TextField, Grid } from '@material-ui/core';
import { useWindowDimensions } from '../rf/Responsive';
import { Refresh, WebAsset, AspectRatio, GpsFixed, CameraAlt, NetworkCell, Message, CastConnected, Save, PhoneAndroid, Send, Speed, Settings, Http } from '@material-ui/icons';
import moment from 'moment';
import { buildNr, version, fetchJSON } from '../swutils'
import { log, error, startlog, endlog } from '../log';
import { useDataState, useValue, loadtport, loadidb } from '../data';
import { ErrorBoundary } from './ErrorBoundary';
import ReactJson from 'react-json-view'


function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function MenuItem({ onClick, icon, label }) {
  return <Grid item xs={6} ms={4} onClick={onClick} style={{ textAlign: 'center' }}><div>{icon}</div>{label}</Grid>
}

export default () => {
  useWindowDimensions();
  let pos = useValue('pos');
  if (pos) pos = pos.pos;
  const config = useValue('config');
  const [eszkozinput, setEszkozInput] = useState('');
  const [devices, setDevices] = useState([]);
  const [sw, setSW] = useState(false);
  const [push, setPush] = useState(localStorage.getItem('subscription'));
  const [noti, setNoti] = useState(localStorage.getItem('notifications'));
  const storage = useValue('storage');
  const [, setNetwork] = useState('');
  const [showState, setShowState] = useState(0);
  let state = useDataState();
  state = { ...state, potkocsilist: state.potkocsilist && state.potkocsilist.length };

  useEffect(() => {

    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then(setDevices);
    }
    if (navigator.connection) navigator.connection.onchange = () => setNetwork(navigator.connection.type);
  }, [setDevices]);

  if (navigator.serviceWorker && !sw) {
    navigator.serviceWorker.ready.then((sw) => {
      setSW(sw);
    });
  }



  async function askUserPermission() {
    try {
      startlog("askUserPermission");
      const result = await Notification.requestPermission();
      log("result", result);
      localStorage.setItem('notifications', result);
      /*   if (result === 'granted') {
           log("testing");
           const n = new Notification('Értesítések bekapcsolva!', {
             body: 'Minta üzenet',
             icon: 'logo192.png'
           });
           log("ready");
         }*/
      setNoti(result);
    } catch (e) {
      error("error", {error:e.stack});
    }
    endlog();
  }

  async function registerForPush() {
    try {
      startlog('requestForPush');
      const vapid = await fetchJSON('GET', '/message/serverKey');
      log('vapid', vapid);
      log('int8array', urlBase64ToUint8Array(vapid));
      const subscription = await sw.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: urlBase64ToUint8Array(vapid) });
      log('subscription', subscription);
      localStorage.setItem('subscription', subscription.toJSON());
      const body = { eszkoz: (config && config.eszkoz) || eszkozinput, subscription: subscription.toJSON() };
      await fetch('/message/subscribe', {
        method: 'POST', body: JSON.stringify(body), headers: {
          'Content-Type': 'application/json',
        },
      })
      setPush(subscription);
      log('ready');
    } catch (e) {
      console.log(e, e.stack);
      error('requestForPush',  {error:e.stack});
    }
    endlog();
  }


  async function unregisterPush() {
    try {
      const s = await sw.pushManager.getSubscription();
      console.log(s);
      if (s) {
        s.unsubscribe();
      }
      localStorage.setItem('subscription', false);
      setPush(false);
      log('ready');
    } catch (e) {
      console.log(e, e.stack);
      error('unregisterPush', {error:e.stack});
    }
    endlog();
  }


  async function sendTestMessage() {
    if (!config || !config.id) alert("Nincs még regisztrálva?");
    try {
      startlog('sendTestMessage');
      const body = {
        "tipus": "message",
        "uzenet": {
          "txt": "Teszt üzenet"
        }
      }
      await fetch('/message/send/' + config.id, {
        method: 'POST', body: JSON.stringify(body), headers: {
          'Content-Type': 'application/json',
        },
      })
      log('ready');
    } catch (e) {
      error('Testmessage',{error:e.stack});
    }
    endlog();
  }


  const onChange = useCallback((ev) => setEszkozInput(ev.target.value), [setEszkozInput]);

  return <ErrorBoundary component="DeviceStatus">
    <Grid container spacing={4} style={{ padding: 16 }}>
      <MenuItem onClick={loadtport} icon={<Refresh />} label="[tport] frissítés" />
      <MenuItem onClick={loadidb} icon={<Refresh />} label="IDB frissítés" />
    </Grid>
    <List>
      <ListItem>
        <ListItemIcon><Http /></ListItemIcon>
        <ListItemText primary={window.location.hostname} />
      </ListItem>

      {config && <>
        <ListItem>
          <ListItemIcon><PhoneAndroid /></ListItemIcon>
          <ListItemText primary={config.eszkoz ? `${config.eszkoz}` : <TextField placholder="Eszköz azonsoító" onChange={onChange} value={eszkozinput} />} secondary={config.kocsi || 'Nincs rendszám regisztrálva!'} />
        </ListItem>
        <ListItem>
          <ListItemIcon><Settings /></ListItemIcon>
          <ListItemText primary={`tport: ${config.id}`}
            secondary={(config.eletjel > 10 ? `Életjel: ${config.eletjel} mp ` : '') + (` Naplózás: ${config.naplozas}`)} />
        </ListItem>
      </>}
      {navigator.connection &&
        <ListItem>
          <ListItemIcon><NetworkCell /></ListItemIcon>
          <ListItemText primary={`${navigator.connection.type || navigator.connection.effectiveType}`}
            secondary={`${navigator.connection.downlink} Mb/s`} />
        </ListItem>
      }
      <ListItem>
        <ListItemIcon><AspectRatio /></ListItemIcon>
        <ListItemText primary={window.screen.width + ' x ' + window.screen.height} secondary={window.screen.orientation.type} />
      </ListItem>
      <ListItem>
        <ListItemIcon><GpsFixed /></ListItemIcon>
        <ListItemText primary={(pos && pos.coords) ? `lat: ${pos.coords.latitude}, lon: ${pos.coords.longitude}` : 'Nincs pozíció'} secondary={(pos && pos.coords) ? `${moment(pos.timestamp).fromNow()} ${Math.round(pos.coords.accuracy)}m pontossággal` : ''} />
      </ListItem>
      <ListItem>
        <ListItemIcon><Speed /></ListItemIcon>
        <ListItemText primary={(pos && pos.coords) ? `${Math.round(pos.coords.speed * 3.6)} km/h` : 'Nincs sebsség'} secondary={(pos && pos.coords) ? `${Math.round(pos.coords.heading) || '?'}°` : 'Nincs irány'} />
      </ListItem>
      <ListItem>
        <ListItemIcon><CameraAlt /></ListItemIcon>
        <ListItemText primary={devices.length + ' elérhető eszköz'} secondary={devices.map(d => {
          return `${d.label} (${d.kind})`;
        }).join(', ')} />
      </ListItem>
      <ListItem>
        <ListItemIcon><Message /></ListItemIcon>
        <ListItemText primary={<Button onClick={askUserPermission}>Engedélyezés</Button>} secondary={
          (noti ? "Értesítések: " + noti + " " : '') + ('wakeLock' in navigator ? ' wakeLock: available' : ' nincs wakeLock API')
        } />
      </ListItem>

      <ListItem>
        <ListItemIcon><Save /></ListItemIcon>
        <ListItemText primary="Tárhely"
          secondary={storage ? `${(storage.usage / (1024 * 1024)).toFixed(0)}MB / ${(storage.quota / (1024 * 1024)).toFixed(0)}MB (${(storage.usage / storage.quota * 100).toFixed(2)}%)` :
            'Nincs infó'} />
      </ListItem>

      <ListItem>
        <ListItemIcon><WebAsset /></ListItemIcon>
        <ListItemText onClick={() => setShowState(showState + 1)} primary={`[ t d r i v e r ] ${version} | ${buildNr}`} secondary={navigator.appVersion} />
      </ListItem>

      {showState > 2 &&       <ListItem>
        <ListItemIcon><CastConnected /></ListItemIcon>
        <ListItemText primary={sw ? <>
          <Button onClick={registerForPush}>Feliratkozás</Button>
          {push ? <Button onClick={unregisterPush}>Leiratkozás</Button> : null}
        </> : 'ServiceWorker nincs telepítve'} secondary={
          (push ? 'PushMessage ' + JSON.stringify(push) : 'Nincs feliratkozva')
        } />
        <ListItemSecondaryAction>
          <IconButton onClick={sendTestMessage}><Send /></IconButton>
        </ListItemSecondaryAction>
      </ListItem>}

      {showState > 4 &&       <ListItem>
        <ListItemIcon><WebAsset /></ListItemIcon>
        <ListItemText onClick={() => setShowState(showState + 1)} primary={`[ t d r i v e r ] ${version} | ${buildNr}`} secondary={navigator.appVersion} />
      </ListItem>
}

    </List>
    {showState > 4 && <ReactJson src={state} theme="tomorrow" indentWidth={2} displayDataTypes={false} collapseStringsAfterLength={15} shouldCollapse={field => ['msg', 'jarat', 'tetel', 'sajattetel', 'potkocsilist'].includes(field.name)} />}

  </ErrorBoundary>
}