import React, { useEffect } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Fab from '@material-ui/core/Fab';

import Login from './Login';
import Vezerlopult from '../vezerlopult/Vezerlopult';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { LocationOn, Chat, Apps, Speed, Send } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import DeviceStatus from './DeviceStatus';
import RegisterDevice from './RegisterDevice';
import { Dialog, registerDialog, useDialog } from '../rf/Dialog';


//import { Sidebar } from './Sidebar';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useDrawer } from '../hooks';
import { usePositionTracker, useValue, checkmsg, syncMsg, useDispatch } from '../data';
import { MessageList, Inbox } from './MessageList';
import { SnackbarProvider } from 'notistack';
import UzenetKuldes from '../dlg/UzenetKuldes';
import { FuvarList } from '../fuvar/FuvarList';
import { FuvarDetails } from '../fuvar/FuvarDetails';
import EgyebMenu from './EgyebMenu';

import { ErkezesDlg, IndulasDlg, MuszakKezdetDlg } from '../dlg/IdoDlg';
import { FelakasztasDlg, LeakasztasDlg } from '../dlg/AkasztasDlg';
import { KmDlg, ResetKmDlg } from '../dlg/KmDlg';
import { HutesKiDlg, HutesBeDlg } from '../dlg/HutesDlg';
import { ErrorBoundary } from './ErrorBoundary';
import { Paper } from '@material-ui/core';
import KarJelentes from '../dlg/KarJelentes';
import { CiklusVegeDlg, LogoutDlg } from '../dlg/ValtasDlg';
import { SOSWindow } from '../sos/SOSWindow';
import { JKonyvDlg,UrlapDlg } from '../jegyzokonyv';
import { DoksiDlg } from '../dlg/Doksik';

registerDialog({ id: 'erkezes', Component: ErkezesDlg });
registerDialog({ id: 'indulas', Component: IndulasDlg });
registerDialog({ id: 'felakasztas', Component: FelakasztasDlg });
registerDialog({ id: 'leakasztas', Component: LeakasztasDlg });
registerDialog({ id: 'kmora', Component: KmDlg });
registerDialog({ id: 'resetkmora', Component: ResetKmDlg });
registerDialog({ id: 'hutesbe', Component: HutesBeDlg });
registerDialog({ id: 'huteski', Component: HutesKiDlg });
registerDialog({ id: 'karjelentes', Component: KarJelentes });
registerDialog({ id: 'ciklusvege', Component: CiklusVegeDlg });
registerDialog({ id: 'logout', Component: LogoutDlg });
registerDialog({ id: 'jkonyv', Component: JKonyvDlg });
registerDialog({ id: 'urlap', Component: UrlapDlg });
registerDialog({ id: 'muszakkezdet', Component: MuszakKezdetDlg });
registerDialog({ id: 'doksik', Component: DoksiDlg });


const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexGrow: 100,
    overflowY: 'scroll',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

let TopNav = props => {
  const location = useLocation();
  const history = useHistory();
  return <BottomNavigation
    value={location.pathname}
    onChange={(event, value) => {
      console.log("push", value);
      history.push(value);
    }}
    showLabels
  /*  variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"*/
  >
    <BottomNavigationAction icon={<Speed />} label="Műszerfal" value="/" />
    <BottomNavigationAction icon={<Chat />} label="Üzenetek" value="/inbox" />
    <BottomNavigationAction icon={<LocationOn />} label="Fuvarok" value="/jarat" />
    <BottomNavigationAction icon={<Apps />} label="Egyéb" value="/egyeb" />

  </BottomNavigation>
}

let AppContent = props => {
  usePositionTracker();
  let valtasDispatch = useDispatch('ciklus');
  let ciklus = useValue('ciklus');
  let { setDialog } = useDialog();
  const classes = useStyles();
  const token = useValue('token');
  const config = useValue('config');
  const sos = useValue('sos');
  console.log("SOS App", sos);
  const { drawer, openDrawer, closeDrawer } = useDrawer();

  useEffect(() => {
    console.log("start checkmsg timer");
    checkmsg();
    let t = setInterval(syncMsg, 10 * 60000);
    return () => {
      clearInterval(t);
    }
  }, []);

  useEffect(() => {
    //      console.log("start ciklus timer");
    let t = setInterval(() => {
      valtasDispatch('checkvege');
    }, 10 * 60000);
    return () => {
      clearInterval(t);
    }
  }, [token, ciklus, setDialog, valtasDispatch]);



  const SendButton = () => <Fab className={classes.fab} color="primary" onClick={openDrawer('send')}><Send /></Fab>

  return <SnackbarProvider hideIconVariant={true}>
    {!token ? (!config || !config.kocsi) ? <RegisterDevice /> : <Login /> :
      <div className={classes.root}>
        {sos ? <SOSWindow fab={<SendButton />} /> : <>
          <Paper elevation={2} >
            <TopNav />
          </Paper>
          <ErrorBoundary component="app">
            <div className={classes.content}>
              <Switch>
                <Route exact path='/' component={Vezerlopult} />
                <Route path='/inbox' component={MessageList} />
                <Route path='/jarat' exact component={FuvarList} />
                <Route path='/jarat/:id' component={FuvarList} />
                <Route path='/status' component={DeviceStatus} />
                <Route path='/egyeb' component={EgyebMenu} />
              </Switch>
            </div>
          </ErrorBoundary>
          <ErrorBoundary component="main fab">
            <Switch>
              <Route path='/inbox' component={SendButton} />
            </Switch>
          </ErrorBoundary>
        </>}


        <ErrorBoundary component="Drawers">
          <SwipeableDrawer anchor="left" open={!!drawer.inbox} onClose={closeDrawer} onOpen={openDrawer('inbox')}>
            <Inbox />
          </SwipeableDrawer>
          <SwipeableDrawer anchor="right" open={!!drawer.jarat} onClose={closeDrawer} onOpen={openDrawer('jarat')}>
            <FuvarDetails />
          </SwipeableDrawer>
          <SwipeableDrawer anchor="bottom" open={!!drawer.send} onClose={closeDrawer} onOpen={openDrawer('send')}>
            <UzenetKuldes close={closeDrawer} />
          </SwipeableDrawer>
        </ErrorBoundary>
        <ErrorBoundary component="Dialog">
          <Dialog />
        </ErrorBoundary>
      </div>
    }
  </SnackbarProvider>

}

export default AppContent;
