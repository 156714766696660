import React from 'react';
import { Grid, Typography, Button, Fab, List, ListItem, ListItemText, ListItemIcon} from '@material-ui/core';
import { useDrawer } from '../hooks';
import { CimLink, gpsAddr } from './Celpont';
import { stampToMoment } from '../../utils.mjs';
import { FlightTakeoff, FlightLand, Publish, GetApp, Cached, LocalParking, Cancel, CheckBoxOutlineBlank, Warning, AcUnit, CheckBoxOutlined, Close, SubdirectoryArrowRight, ErrorOutline} from '@material-ui/icons';
import { Link } from '../rf/Link';

import { useDialog } from '../rf/Dialog';
import { useAktivJarat, useDispatch, useValue } from '../data';
import { ErrorBoundary } from '../app/ErrorBoundary';
import { SoforStatus } from '../vezerlopult/SoforStatus';
import { JKonyvGomb } from '../jegyzokonyv';

const itemstyle = { textAlign: 'center', marginBottom: 16, paddingBottom: 16, borderBottom: '1px solid #999' };

function Celpont({ t, idx }) {
  const potkocsi = useValue('potkocsi');
  const { openDrawer } = useDrawer();
  const details = () => openDrawer('jarat', { jarat: t.jarat })();
  const { setDialog } = useDialog();

  const eterv = stampToMoment(t.eterv);
  const eteny = stampToMoment(t.eteny);
  const iterv = stampToMoment(t.iterv);
  const iteny = stampToMoment(t.iteny);
  const kesz = t.eteny && t.iteny;

  let Icon = null;
  switch (t.jelleg) {
    case 1: Icon = Publish; break;
    case 2: Icon = Cached; break;
    case 3: Icon = Cached; break;
    case 4: Icon = GetApp; break;
    default: break;
  }
  console.log("tetel",idx,t);
  if (t.mtipus === 2) {
    Icon = SubdirectoryArrowRight;
    if(idx===0){ //első pont nem felraó, csak innen kell indulni.
      if (kesz) {
        return <Grid container spacing={0} style={itemstyle}>
          <Grid item xs={6}><Typography onClick={details} variant="body2">Indulás</Typography></Grid>
          <Grid item xs={6}><Typography onClick={() => potkocsi ? setDialog({ id: 'erkezes', t }) : setDialog({ id: 'felakasztas' })} variant="h6" style={{ color: "#00ff0099" }}>{
            eteny.format("HH:mm")}</Typography></Grid>
        </Grid>
      }
      return <Grid container spacing={2} style={{ textAlign: 'center', marginBottom: 16, paddingBottom: 16, borderBottom: '1px solid #999' }}>
        <Grid item xs={6} sm={3}><Typography onClick={details} variant="h4"> Indulás </Typography></Grid>
        <Grid item xs={6} sm={3}><Typography onClick={() => setDialog({ id: 'erkezes', t })} variant="h4" style={{ color: t.eteny ? "#00ff00bb" : "inherit" }}>{
          t.eteny ? eteny.format("HH:mm") : eterv.format("HH:mm")}</Typography></Grid>
      </Grid>      
    }
  }


  if (kesz) {
    return <Grid container spacing={0} style={itemstyle}>
      <Grid item xs={12}><Typography onClick={details} variant="body2"><Icon style={{ verticalAlign: 'bottom' }} /> {t.prnev || t.pdisplay}</Typography></Grid>
      <Grid item xs={6}><Typography onClick={() => potkocsi ? setDialog({ id: 'erkezes', t }) : setDialog({ id: 'felakasztas' })} variant="h6" style={{ color: "#00ff0099" }}><FlightLand /> {
        eteny.format("HH:mm")}</Typography></Grid>
      {!t.csakerkezes && <Grid item xs={6}><Typography onClick={() => potkocsi ? setDialog({ id: 'indulas', t }) : setDialog({ id: 'felakasztas' })} variant="h6" style={{ color: "#00ff0099" }}><FlightTakeoff /> {
        iteny.format("HH:mm")}</Typography></Grid>}
    </Grid>
  }
  return <Grid container spacing={2} style={{ textAlign: 'center', marginBottom: 16, paddingBottom: 16, borderBottom: '1px solid #999' }}>
    <Grid item xs={6} sm={3}><Typography onClick={details} variant="h4"><Icon style={{ verticalAlign: 'bottom' }} fontSize="large" /> {t.pdisplay} {t.infos? <ErrorOutline/>:null} </Typography></Grid>
    <Grid item xs={6} sm={3}><Typography onClick={details} variant="h6">{t.prnev}</Typography></Grid>
    <Grid item xs={6} sm={3}><Typography onClick={() => setDialog({ id: 'erkezes', t })} variant="h4" style={{ color: t.eteny ? "#00ff00bb" : "inherit" }}><FlightLand /> {
      t.eteny ? eteny.format("HH:mm") : eterv.format("HH:mm")}</Typography></Grid>
    {!t.csakerkezes && <Grid item xs={6} sm={3}><Typography onClick={() => setDialog({ id: 'indulas', t })} variant="h4" style={{ color: t.iteny ? "#00ff00bb" : "inherit" }}><FlightTakeoff /> {
      t.iteny ? iteny.format("HH:mm") : (t.iterv ? iterv.format("HH:mm") : '__:__')}</Typography></Grid>}
    {!t.eteny && <><Grid item xs={9} style={{ textAlign: 'left', paddingLeft: 24 }}>
      <CimLink t={t} />
    </Grid>
      <Grid item xs={3}>
        <Link directions={gpsAddr(t)} />
      </Grid></>}
  </Grid>
}



export default () => {
  const { jarat, select } = useAktivJarat();
  const { openDrawer } = useDrawer();
  const { setDialog } = useDialog();
  const potkocsi = useValue('potkocsi');
  const dispatchJarat = useDispatch('jarat');
  const pihi = useValue('piheno');

  if (!jarat || !jarat.tetel || !jarat.tetel.length) return <SoforStatus /> //ha menetközben kilőtték alóla a fuvart.

  const sajat = jarat.sajattetel || jarat.tetel.filter(t => t.sajat);
  if (!sajat || !sajat.length) return <SoforStatus /> //ha menetközben kilőtték alóla a fuvart.
  
  const kesz = sajat.filter(t => !t.eteny).length === 0;



  let warning = null;
  if (!potkocsi || !potkocsi.frsz) {
    warning = <Grid item xs={12} onClick={() => setDialog({ id: 'felakasztas' })} > <div style={{ margin: 16, border: '2px solid #f00', padding: 8, background: '#fff', color: '#f00', textAlign: 'center' }}>
      <Warning /><br />
    Nem adta meg a pótkocsi rendszámát!<br />Ide kattintva állítsa be most.</div></Grid>
  } else {
    if (potkocsi.frsz !== sajat[0].potkocsi) {
      warning = <Grid item xs={12} onClick={() => setDialog({ id: 'felakasztas' })} > <div style={{ margin: 16, border: '2px solid #f00', padding: 8, background: '#fff', color: '#f00', textAlign: 'center' }}>
        <Warning /><br />
      A járatra beosztott pótkocsi:<Typography variant="h6">{sajat[0].potkocsi}</Typography>
      Jelenleg a program szerint a <b>{potkocsi.frsz}</b> pótkocsit húzza.
      Ellenőrzés után ide kattintva átállíthatja.</div></Grid>
    } else if (sajat[0].frigo && !potkocsi.hutes) {
      warning = <Grid item xs={12}> <div style={{ margin: 16, border: '2px solid #f00', padding: 8, background: '#fff', color: '#009', textAlign: 'center' }}>
        <div style={{ textAlign: 'right', color: '#bbb' }} onClick={() => dispatchJarat('nofrigo', { jarat: jarat.pos, tetel: sajat[0].sorszam })} >
          <Close fontSize="small" style={{ verticalAlign: 'bottom' }} />
        </div>

        <AcUnit /><br />
      Hütőtt áruról van szó!<br /> Miután bekapcsolta a hűtést <br />
        <Button onClick={() => setDialog({ id: "hutesbe" })} variant="outlined" color="primary">Ide kattintva jelezze</Button>
      </div></Grid>
    }
  }

  console.log("Járat:", jarat);
  let info = [];
  let nemtankol = null;
  if(jarat){
    info.push(jarat.pos);
    if(jarat.fuvar && jarat.fuvar.mref) info.push(jarat.fuvar.mref);
    if(jarat.nemtankol) nemtankol = <Grid item xs={12}>
      <div style={{ margin: 4, textAlign: 'center', display:'flex',alignItems:'center',justifyContent:'center' }}>
      <Warning fontSize="small"/>
      <div>Nincs tankolás!</div>
    </div>
    </Grid>
  }
  if(potkocsi){
    info.push(potkocsi.frsz);
    if(potkocsi.hutes) info.push(`${potkocsi.hofok} °C`);
  }
  if(info.length){
    info = <Grid item xs={12} style={itemstyle}><div style={{ paddingTop: 16 }}>
      {info.join(" | ")}
    </div>
    </Grid>
  }


  const ures = sajat && sajat.length && sajat[0].mtipus === 2;


  return <ErrorBoundary component="AktivFuvar">
    {warning}
    {nemtankol}
    {info}
    {sajat.map((t,i) => <Celpont key={t.sorszam} t={t} idx={i}/>)}
    <List>
      {!kesz && !!sajat[0].frigo && <JKonyvGomb u="huto" checked={jarat.hutolapsent} /> }
      {!kesz && !!sajat[0].tapa && <JKonyvGomb u="tapa1" checked={jarat.tapa1sent} /> }
      {!kesz && !!sajat[0].tapa && <JKonyvGomb u="tapa2" checked={jarat.tapa2sent} /> }
      {!kesz && !!sajat[0].tapa && <JKonyvGomb u="tapa3" checked={jarat.tapa3sent} /> }

      {!kesz && !ures && sajat[0].eteny && <ListItem onClick={openDrawer('send', { id: 'feltoltes', jarat: jarat.pos, tipus: 3 })}><ListItemIcon>{jarat.plombasent ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}</ListItemIcon><ListItemText primary="Plomba fotó" secondary="ide kattintva feltöltheti" /></ListItem>}
      {kesz && !ures && <ListItem onClick={openDrawer('send', { id: 'feltoltes', jarat: jarat.pos, tipus: 1 })}><ListItemIcon>{jarat.crmsent ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}</ListItemIcon><ListItemText primary="CMR feltöltése" secondary="ide kattintva feltöltheti" /></ListItem>}
      {kesz && <ListItem onClick={openDrawer('send', { id: 'feltoltes', jarat: jarat.pos, tipus: 2 })}><ListItemIcon>{jarat.menetlevelsent ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}</ListItemIcon><ListItemText primary="Menetlevél feltöltése" secondary="ide kattintva feltöltheti" /></ListItem>}
      {potkocsi && !!potkocsi.hutes && <ListItem onClick={() => setDialog({ id: 'huteski' })}><ListItemIcon><AcUnit /></ListItemIcon><ListItemText primary={`Hűtő bekapcsolva ${potkocsi.hofok}°C`} secondary="ide kattintva jelezze, ha kikapcsolta" /></ListItem>}
      {ures ? <ListItem onClick={() => select(false)}><ListItemIcon><Cancel /></ListItemIcon><ListItemText primary="Üres átállás kész" secondary="ide kattintva kiléphet a feladatból" /></ListItem> :
        <ListItem onClick={() => select(false)}><ListItemIcon><Cancel /></ListItemIcon><ListItemText primary="Feladat befejezve" secondary="ide kattintva kiléphet a feladatból" /></ListItem>}
    </List>
    {!pihi && <Fab color="primary" style={{ position: 'fixed', bottom: 8, right: 8 }} onClick={openDrawer('send', { id: 'piheno' })}><LocalParking /></Fab>}
  </ErrorBoundary>
}