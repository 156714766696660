import React, { useState } from 'react';
import { Button, Slider} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField } from '../rf/input/Text';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import { useValue, useDispatch, useAuth } from '../data';
import { ChevronLeft, ChevronRight, Hotel, PowerSettingsNew } from '@material-ui/icons';
import { dateToMoment } from '../../utils.mjs';




export function NapOffsetInput(props) {
  console.log("default:", props.default.format('yyyy.MM.DD.'));

  return <Field name="offset" type="number">{
    ({ field: { value }, form: { setFieldValue } }) => {
      const set = (v) => {
        setFieldValue('offset', v);
        setFieldValue('datum', moment(props.default).add(v, 'd'));
      }

      return <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div onClick={() => set(value - 1)}><ChevronLeft fontSize="large" /></div>
          <div style={{ fontSize: 24, textAlign: 'center', flexGrow: 100 }}>{moment(props.default).add(value, 'd').format('MM.DD. dddd')}</div>
          <div><ChevronRight fontSize="large" onClick={() => set(value + 1)} /></div>
        </div>
        <Slider
          step={1}
          min={-5}
          max={5}
          value={value}
          onChange={(ev, val) => set(val)}
        />
      </div>
    }
  }
  </Field >
}


export function CiklusVegeDlg({ open: { t }, close, reset }) {
  const sofor = useValue('sofor');
  const { vegejelzes } = useValue('ciklus');
  const dispatch = useDispatch('ciklus');
  let terv = moment().add(sofor && sofor.mciklus, 'd');
  let offset = 0;
  let datum = terv;
  if (vegejelzes) { //korábban már lejelnetette, azt dobjuk fel:
    if (vegejelzes.terv) terv = dateToMoment(vegejelzes.terv);
    offset = vegejelzes.offset || 0;
    datum = (vegejelzes.datum && dateToMoment(vegejelzes.datum)) || moment(terv).add(offset, 'd');
  }


  const validate = ({ offset, txt }) => {
    if (offset && !txt) return { txt: 'Írjon indoklást' };
    return {};
  }

  const onSubmit = async ({ offset, datum, txt }) => {
    await dispatch('ciklusvege', { offset, datum, txt, terv });
    close({});
  }

  return <Formik initialValues={{ offset, datum }} validate={validate} onSubmit={onSubmit}>
    <Form>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
        <DialogContent>
          <h2>Tervezett hazaérkezés</h2>
          <NapOffsetInput default={terv} />
          <TextField name="txt" fullWidth autoFocus variant="filled" multiline rows={4} />
          <br /><br />
          <div>
            A program egy üzenetben jelzi a diszpécsereknek a tervezett haza érkezés napját. A ténylegesen rögzített dátumot a vezérlőpulton tudja ellenőrizni.
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            dispatch('cancel',{});
            close();
          }
          } >Mégsem</Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            type="submit"
            variant="contained"
            color="primary">
            Ciklus végének jelzése
      </Button>
        </DialogActions>
      </div>
    </Form></Formik>
}


export function LogoutDlg({ open: { t }, close, reset }) {
  const { logout } = useAuth();
  const [form, showForm] = useState(false);
  const sofor = useValue('sofor');
  const dispatch = useDispatch('ciklus');
  let terv = moment().add(sofor && sofor.pciklus, 'd');
  let offset = 0;
  let datum = terv;

  if (!form) {
    return <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: 230 }}>
        <Button onClick={() => showForm(true)} variant="contained" startIcon={<Hotel/>}>
          Munkaciklus vége
        </Button>
        <Button onClick={()=>{
          close({});
          logout();
        }} variant="contained" startIcon={<PowerSettingsNew/>}>
          Kijelentkezés
        </Button>
      </div>
    </DialogContent>
  }

  const validate = ({ offset, txt }) => {
    //    if (offset && !txt) return { txt: 'Írjon indoklást' };
    return {};
  }

  const onSubmit = async ({ offset, datum, txt }) => {
    await dispatch('cikluskezdet', { offset, datum, txt, terv });
    close({});
    logout();
  }

  return <Formik initialValues={{ offset, datum }} validate={validate} onSubmit={onSubmit}>
    <Form>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
        <DialogContent>
          <h2>Következő munkaciklus első napja</h2>
          <NapOffsetInput default={terv} />
          <TextField name="txt" fullWidth autoFocus variant="filled" multiline rows={4} />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} >Mégsem</Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            type="submit"
            variant="contained"
            color="primary">
            Ciklus kezdetének jelzése
      </Button>
        </DialogActions>
      </div>
    </Form></Formik>
}
