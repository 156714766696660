/* eslint-disable no-restricted-globals*/

import { log } from './log.js';
import { get, set } from 'idb-keyval';
import preval from 'preval.macro';
import pkg from '../../package.json';
import { fetch as fetchPolyfill } from 'whatwg-fetch'

export const buildNr = preval`module.exports = new Date().toLocaleString();`;
export const version = pkg.version;


export function formatStamp(d) {
  return d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
}

export function now() {
  return formatStamp(new Date());
  /*const d = new Date();
  return d.toISOString().substr(0,19).replace('T',' ');*/
}

export const buildUrl = (url, p) => {
  let qs = Object.keys(p).map(k => encodeURIComponent(k) + "=" + encodeURIComponent(p[k]));
  if (qs.length > 0) {
    url = url + "?" + qs.join('&');
  }
  return url;
}

async function getStatus() {
  try {
    const config = await get('config') || {};
    const pos = await get('pos');
    const sofor = await get('sofor');
    console.log("getStatus sofor", sofor);
    const storage = await get('storage');
    return {
      eszkoz: config.id,
      kocsi: config.kocsi,
      sofor: sofor && sofor.rnev,
      eszkozido: now(),
      version,
      build: buildNr,
      lat: pos && pos.pos && pos.pos.coords && pos.pos.coords.latitude,
      lon: pos && pos.pos && pos.pos.coords && pos.pos.coords.longitude,
      accuracy: pos && pos.pos && pos.pos.coords && pos.pos.coords.accuracy,
      heading: pos && pos.pos && pos.pos.coords && pos.pos.coords.heading,
      speed: pos && pos.pos && pos.pos.coords && pos.pos.coords.speed,
      pos_stamp: pos && pos.pos && pos.pos.timestamp,
      storage: storage && storage.usage,
      storageperc: storage && (storage.usage / storage.quota * 100).toFixed(2),
      network: navigator && navigator.onLine ? 'online' : 'offline',
      kmora: pos && pos.km,
      kmlat: pos && pos.kmpos && pos.kmpos.coords && pos.kmpos.coords.latitude,
      kmlon: pos && pos.kmpos && pos.kmpos.coords && pos.kmpos.coords.longitude,
      kmszamlalo: pos && pos.szamlalo,
      legvonal: pos && pos.legvonal,
    };
  } catch (e) {
    console.log(e.stack);
    return {};
  }
}


export async function createRequest(method, url, body) {
  //  log("createRequest", { method, url, body, queue });
  let q = {};
  const token = await get('token');

  if (method === 'GET' && body) {
    q = Object.assign(q, body);
    body = false;
  }
  if (method === 'POST') {
    if (!body) body = {};
    body.status = await getStatus();
  }

  url = buildUrl(url, q);
  let prefix = '';
  if (url.substr(0, 2) === '//') {
    prefix = '';
    url = url.substr(1);
  }

  url = prefix + url;
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': "no-cache, no-store, must-revalidate",
    'Pragma': "no-cache",
    'Expires': 0
  };
  if (token) headers['X-Access-Token'] = token;

  return new Request(url, {
    method: method || 'POST',
    headers,
    body: body ? JSON.stringify(body) : null
  });
}

export async function fetchJSON(method, url, body = {}) {
  try {
    const req = await createRequest(method, url, body);
    const response = await fetch(req);
    console.log("response", { response });
    if (response.ok) {
      const ret = await response.json();
      console.log("json", { ret })
      return ret;
    }
    throw new Error(response.error);
  } catch (e) {
    console.error(e.stack);
  }
}

export const notify = ({ event, title, body, actions, data }) => {
  const ret = self.registration.showNotification(title || '[ t d r i v e r ]', {
    body,
    data,
    actions
  });
  if (event) event.waitUntil(ret);
}

function kezdet(f) {
  if (!f) return '';
  if (f && f.tetel && f.tetel.length) {
    if (!f.sajattetel) f.sajattetel = f.tetel.filter(t => t.sajat);
    if (f.sajattetel.length) return f.sajattetel[0].eterv;
  }
  return f.kezdet;
}

export async function fetchJarat(pos, list, msg) {
  log("fetchJarat", pos);
  const f = await fetchJSON('GET', '/jarat', { pos });

  if (!list) list = [];
  if (f && !f.error) {
    f.msg = msg.filter(m => m.sendtour && m.jarat === f.pos).reverse();
    if (f.tetel && f.tetel.length) {
      f.sajattetel = f.tetel.filter(t => t.sajat);
      f.sajat = f.sajattetel.length > 0;
      if (f.sajat) {
        f.kezdet = f.sajattetel[0].eterv;
      }
      f.lezarva = (f.tetel.filter(t => t.sajat && (!t.eteny || !t.iteny)).length === 0);
      console.log("Lezárva? ", f.lezarva);
    }
    let van = list.findIndex(j => j.pos === f.pos);
    if (van === -1) {
      list = [...list, f];
      log("ilyen még nincs: ", list.length);
    } else {
      log("Van ilyen: ", van);
      list.splice(van, 1, f);
      list = [...list];
      log("Van ilyen: ", list.length);
    }
    list.sort((a, b) => {
      a = kezdet(a);
      b = kezdet(b)
      return b > a ? 1 : b < a ? -1 : 0;
    })
  }
  return list;
}



export async function uploadFile(url, file) {
  log("uploadFile", { url });
  try {
    const token = await get('token');
    url = buildUrl(url, {});
    let prefix = '';
    if (url.substr(0, 2) === '//') {
      prefix = '';
      url = url.substr(1);
    }

    url = prefix + url;
    const headers = {
      'Accept': 'application/json',
    };
    if (token) headers['X-Access-Token'] = token;

    log("creating request");

    const response = await fetchPolyfill(url, { method: 'POST', headers, body: file });
    log("Response:", { status: response.status, statusText: response.statusText });
    console.log("response", { response });
    if (response.ok) {
      const ret = await response.json();
      console.log("json", { ret })
      log("json", { ret })
      return ret;
    }
    log("hiba");
    throw new Error(response.error);
  } catch (e) {
    log("uploadError", e.stack);
    console.error(e.stack);
  }
}

var alive = false;
var eletjel = 0;
export function setEletjel(sec) {
  if (sec === eletjel) return;
  eletjel = sec;
  console.log('setEletjel', { sec, online: navigator.onLine });

  if (alive) clearInterval(alive);
  if (sec && sec > 5) {
    alive = setInterval(() => {
      if (navigator.onLine) {
        fetchJSON('POST', '/alive');
      }
    }, sec * 1000);
  } else {
    alive = false;
  }
}

export async function checkEletjel() {
  const config = await get('config');
  if (config) setEletjel(config.eletjel);
}


export async function loadtport() {
  console.log("loadtport");
  const msg = await fetchJSON('GET', '/message/naplo');
  console.log(msg);
  if (msg && !msg.error) {
    let jarat = [];
    const j = Object.keys(msg.filter(m => m.sendtour && m.jarat).reduce((acc, m) => { //ezeket a járatokat küldték le, kérjük el az aktuális állapotot (egyszer)
      acc[m.jarat] = m;
      return acc;
    }, {}));

    for (let i = 0; i < j.length; i++) {
      jarat = await fetchJarat(j[i], jarat, msg);
    }
    jarat.sort((a, b) => {
      a = a.sajattetel && a.sajattetel[0].eterv;
      b = b.sajattetel && b.sajattetel[0].eterv;
      return b > a ? 1 : b < a ? -1 : 0;
    });
    await set('msg', msg);
    await set('jarat', jarat);
  }

  const trancard = await fetchJSON('GET', '/tport/trancard');
  console.log(trancard);
  if (trancard && !trancard.error) {
    await set('trancard', trancard);
  }
  const sablon = await fetchJSON('GET', '/tport/sablon');
  console.log("sablon", sablon);
  if (sablon && !sablon.error) {
    await set('sablon', sablon);
  }


  const urlap = await fetchJSON('GET', '/tport/urlap');
  console.log("urlap", urlap);
  if (urlap && !urlap.error) {
    await set('urlap', urlap);
  }    
}