import React from 'react';
import { Directions } from '@material-ui/icons';
import { Link } from '../rf/Link';
import { Celpont, cimAddr, gpsAddr } from './Celpont';

export function TimeLine({ tetel }) {
  tetel = tetel.filter(t => t.sajat || t.jelleg === 1 || t.jelleg === 4);
  let first = true;
  return <><div style={{ marginLeft: 70 }}>
    {tetel.map((t, i) => {
      let isFirst = false;
      if (t.sajat && first) {
        first = false;
        isFirst = true;
      }
      return <Celpont key={t.sorszam} t={t} first={isFirst} last={i === tetel.length - 1} sajat={t.sajat} />
    })}
  </div>
    <div style={{ display: 'flex', justifyContent: 'space-around', margin: 16 }}>
      <Link directions={tetel.map(cimAddr)}><Directions style={{ verticalAlign: 'bottom' }} /> CÍM ALAPJÁN</Link>
      <Link directions={tetel.map(gpsAddr)}><Directions style={{ verticalAlign: 'bottom' }} /> GPS ALAPJÁN</Link>
    </div>
  </>
}