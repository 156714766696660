import React, { useCallback, useEffect, useState, useContext, createContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { log } from './log';
import { getData, loadidb } from './data';

const AppCtx = createContext();
export const useAppCtx = () => useContext(AppCtx);

export const useDrawer = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  let open = {};
  let page = {};

  if (state && state.drawer) {
    if (state.drawer) open = state.drawer;
    if (state.drawerPage) page = state.drawerPage;
  }

  const openDrawer = useCallback((id, page, op) => () => {
    console.log("OpenDrawer", id, page);
    if (!location.state) location.state = {};
    const prev = { drawer: location.state.drawer && location.state.drawer[id], page: location.state.drawerPage && location.state.drawerPage[id] };
    location.state.drawer = { [id]: true };
    if (page) {
      if (!location.state.drawerPage) location.state.drawerPage = {};
      location.state.drawerPage[id] = page;
    }
    if (prev.drawer) {
      console.log("ugyanaz a drawer", { id, prev, state: location.state });
      if (!prev.page) {
        if (!location.state.drawerPage || !location.state.drawerPage[id]) {
          console.log("Nincs aloldal");
          return;
        }
      } else if (location.state.drawerPage && prev.page === location.state.drawerPage[id]) {
        console.log('ugyanaz az oldal');
        return;
      }
    }

    op === 'replace' ? history.replace(location) : history.push(location);
  }, [history, location]);

  const closeDrawer = useCallback(() => {
    console.log("closeDrawer", location.state);
    if (location && location.state && location.state.drawer) {
      history.goBack();
    }
  }, [history, location]);

  return useMemo(() => ({ drawer: open, page, openDrawer, closeDrawer }), [open, page, openDrawer, closeDrawer]);
};


export const useWakeLock = () => {
  const [wakeLock, setWakeLock] = useState(false);
  useEffect(() => {
    try {
      if ('wakeLock' in navigator) {
        if (!wakeLock) {
          log('request wakeLock');
          navigator.wakeLock.request('screen').then((w) => {
            w.addEventListener('release', () => {
              log("Released wakelock");
              setWakeLock(false);
            });
            setWakeLock(w)
          }).catch(e => {
            console.log("failed wakelock");
          });
        }
      } else {
      }
    } catch (e) {
      log("nem sikerült a wakelock");
    }
  }, [wakeLock])

  useEffect(() => {  //cleanup
    if (wakeLock) {
      return () => {
        log('release WakeLock');
        wakeLock.release();
      }
    }
  }, [wakeLock])
}

export const AppContext = ({ children }) => {
  const [channel] = useState(new BroadcastChannel('sw-messages'));
  //  const { openDrawer, drawer } = useDrawer();

  useEffect(() => {
    console.log("add channel listener");
    channel.addEventListener('message', event => {
      console.log('Received', event.data);
      if (event.data) {
        const { data } = event;
        if (data === 'F5') window.location.reload();
        if (data === 'loadidb') loadidb();
        if (data.refresh && getData(data.refresh)) {
          console.log("refresh", data.refresh);
          getData(data.refresh).refresh().then(() => {
            /* if (data.refresh === 'msg' && window.location.href !== '/inbox' && !drawer.inbox) {
               const unread = getData('msg').val.filter(m => !m.nysofor);
               console.log("checkunread", unread);
               if (unread.length) {
                 console.log("openDrawer unread");
             //    openDrawer('inbox')();
               }
             }*/
          })
        }
      }
    });
  }, [channel]);

  const value = {
    channel
  }
  return <AppCtx.Provider value={value}>{children}</AppCtx.Provider>
}

export const demo = window.location.hostname.includes('teszt') || window.location.port === '3000';
