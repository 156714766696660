import React, { useState, useEffect } from 'react';
import { Button, LinearProgress, Grid } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { fetchJSON } from '../swutils';
import config from '../../config.json';


export function DoksiDlg({ open: { t }, close, reset }) {
  const [files, setFiles] = useState(false);
  useEffect(() => {
    if (config.documents) {
      fetchJSON('GET', '/documents').then(setFiles);
      let to = setTimeout(close, config.documents.expires_min * 60000);
      return () => clearTimeout(to);
    }
  }, [close, setFiles])

  const list = files ? <Grid container spacing={3}>{files.length ? files.map(f => {
    return <Grid item xs={6} key={f.url} ><Button variant="contained" href={'/documents/' + f.url} target="_docs" style={{ width: '100%' }}>{f.name}</Button></Grid>
  }) : <Grid item xs={12}>Nincsenek letölthető dokumentumok <br /><Button color="primary" variant="contained" onClick={close} >Kilép</Button></Grid>}</Grid> :
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', textAlign: 'center', height: '100%', overflowY: 'auto' }}>
      <div>
        <LinearProgress />
        Dokumentumok keresése {files}
      </div>
    </div>

  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%' }}>
    <DialogContent >
      {list}
    </DialogContent>
    <DialogActions>
      <Button onClick={close} >Kilép</Button>
    </DialogActions>
  </div>
}
